import axios from 'axios'
import types from './ActionTypes'

import { getHmacTokenAndTimestamp } from './helper/hmacHelper'

const hmacResponse = getHmacTokenAndTimestamp()

const API = {}
let headers = {
  Accept: 'application/json',
  'Asurion-Client': 'HARVEY_NORMAN',
  'Asurion-channel': 'SitePortal',
  'Asurion-enduser': '',
  'Asurion-lineofbusiness': 'MOBILITY',
  'Asurion-region': 'APAC-SEA',
  'Content-Type': 'application/json',
  'x-api-key': process.env.API_KEY,
}

API[types.INITIALIZE_REQUEST] = () =>
  axios
    .get('/api/store/v3/masterdata', {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_RETURN_LABEL] = (TradeId) =>
  axios
    .get(`/api/store/v3/returnlabel/${TradeId}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_MASTERDATA_REQUEST] = (tokenHeaders) =>
  axios.get('api/store/v3/masterdata', {
    headers: (headers = Object.assign({}, headers, {
      'x-asurion-cognito': tokenHeaders && tokenHeaders.IdToken,
      'Asurion-enduser': tokenHeaders && tokenHeaders.StoreAgentName,
      'Asurion-Application': process.env.REACT_APP_TYPE,
    })),
  })

API[types.GET_LOGISTICES_BATCH_REQUEST] = (data) =>
  axios.post('api/store/v3/getbatchdetails', data, {
    headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
  })

API[types.GET_CITY_STATE_REQUEST] = (zipcode) =>
  axios
    .get(`/api/store/v3/citystates/${zipcode}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CHANGE_PASSWORD_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/changetemppassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

// API[types.LOGIN_REQUEST] = (data) =>
//   axios
//     .post('/api/store/login', data, {
//       headers,
//     })
//     .then((res) => res)

// console.log('getHmacTokenAndTimestamp',getHmacTokenAndTimestamp())

API[types.LOGIN_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/login', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_LOGISTICES_REQUEST] = (data) =>
  axios
    .post('api/store/v3/getdevicelist', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CREATE_BATCH_REQUEST] = (data) =>
  axios
    .post('api/store/v3/createbatch', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.FORGORT_PASSWORD_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/forgotpassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
API[types.CONFIRM_PASSWORD_REQUEST] = (data) =>
  axios
    .post('api/store/v3/confirmpassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CREATE_PARCEL_REQUEST] = (data) => axios.post('api/parcel/v3/create', data, { headers }).then((res) => res)

API[types.GET_FILE_REQUEST] = (data) =>
  axios
    .get('api/store/v3/tradeincarecreditreport', {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.DOWNLOAD_FILE_REQUEST] = (data) =>
  axios
    .get(`api/store/v3/filedownload/${data}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CREATE_PARCEL_ONSUBMIT_REQUEST] = (data) => axios.post('api/parcel/v3/create', data, { headers }).then((res) => res)

API[types.CREATE_BATCH_ONSUBMIT] = (data) =>
  axios
    .post('api/store/v3/createbatch', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_REPORTING_DETAILS_REQUEST] = (data) =>
  axios
    .post('api/store/v3/getreportsummary', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.SEND_EMAIL_LABEL_REQUEST] = (data) =>
  axios
    .post(`api/store/v3/sendemail`, data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_DAX_IMAGE_REQUEST] = (data) =>
  axios.get(`api/store/v3/getdaximageurls?redemptionOrderId=${data}`, {
    headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
  })

API[types.GET_CONTACT_US_MASTER_DATA] = (data) =>
  axios.get(`api/store/v3/getcontactusmasterdetails`, {
    headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
  })

API[types.SEND_ENQUIRY_EMAIL_REQUEST] = (data) =>
  axios
    .post('api/store/v3/sendenquiryemail', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_TRADE_DETAILS] = (data) =>
  axios.get(`api/store/v3/getTradeDetails/${data.upgradeid}?payrollid=${data.payrollid}&upgradetab=${data?.upgradetab}`, {
    headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
  })

API[types.SUBMIT_TRADE_DETAILS] = (data) =>
  axios
    .post('api/store/v3/submittrade', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
API[types.SUBMIT_TRADE_DETAILS_V2] = (data) =>
  axios
    .post('api/store/v3/submittrade', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_PRE_SIGNED_URL] = (data) =>
  axios
    .get(`/api/store/v3/getpresignurl/${data.tradeId}/${data.imageName}?method=${data.method}&documentType=Qr_Code`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.UPLOAD_FILE_TO_S3] = (data) =>
  axios
    .put(data.url, data.file, {
      headers: { 'Content-Type': 'image/png' },
    })
    .then((res) => res)

API[types.VERIFY_IMEI] = (data) =>
  axios
    .post('api/store/v3/verifyimei', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => {
      return res.data?.ImeiVerificationsResponse[0]?.results?.FMIP?.response?.pass
    })

export default API
