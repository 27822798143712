export default {
  SET_EMAIL_ID: 'SET_EMAIL_ID',
  OPEN_POP_UP: 'OPEN_POP_UP',
  CLOSE_POP_UP: 'CLOSE_POP_UP',
  API_ERROR: 'API_ERROR',
  UNSET_API_ERROR: 'UNSET_API_ERROR',
  SET_USER_AUTHENTICATION: 'SET_USER_AUTHENTICATION',
  GO_TO_ORDER_DETAILS: 'GO_TO_ORDER_DETAILS',
  SET_CUSTOMER_TRADE_ID: 'SET_CUSTOMER_TRADE_ID',
  FIND_TRADE_INFO_REQUEST: 'FIND_TRADE_INFO_REQUEST',
  FIND_TRADE_INFO_SUCCESS: 'FIND_TRADE_INFO_SUCCESS',
  FIND_TRADE_INFO_FAILURE: 'FIND_TRADE_INFO_FAILURE',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_CAPTCHA: 'SET_CAPTCHA',
  SET_STORE_NAME: 'SET_STORE_NAME',
  GET_MASTERDATA_REQUEST: 'GET_MASTERDATA_REQUEST',
  GET_MASTERDATA_SUCCESS: 'GET_MASTERDATA_SUCCESS',
  GET_RETURN_LABEL: 'GET_RETURN_LABEL',
  GET_RETURN_LABEL_SUCCESS: 'GET_RETURN_LABEL_SUCCESS',
  GET_RETURN_LABEL_FAILURE: 'GET_RETURN_LABEL_FAILURE',
  SET_MOBILE_NUMBER: 'SET_MOBILE_NUMBER',
  SET_BILLING_ACCOUNT_NUMBER: 'SET_BILLING_ACCOUNT_NUMBER',
  VALIDATE_CUSTOMER_REQUEST: 'VALIDATE_CUSTOMER_REQUEST',
  VALIDATE_CUSTOMER_SUCCESS: 'VALIDATE_CUSTOMER_SUCCESS',
  VALIDATE_CUSTOMER_FAILURE: 'VALIDATE_CUSTOMER_FAILURE',
  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_EMAIL_ADDRESS: 'SET_EMAIL_ADDRESS',
  SET_ADDRESS_LINE_1: 'SET_ADDRESS_LINE_1',
  SET_ADDRESS_LINE_2: 'SET_ADDRESS_LINE_2',
  SET_ADDRESS_LINE_3: 'SET_ADDRESS_LINE_3',
  SET_POSTAL_CODE: 'SET_POSTAL_CODE',
  SET_CITY_NAME: 'SET_CITY_NAME',
  SET_SELECTED_STATE: 'SET_SELECTED_STATE',
  SET_SELECTED_COUNTRY: 'SET_SELECTED_COUNTRY',
  SET_PRIMARY_IMEI_CHECK: 'SET_PRIMARY_IMEI_CHECK',
  SET_SECONDARY_IMEI_CHECK: 'SET_SECONDARY_IMEI_CHECK',
  SET_DEVICE_MODEL_CHECK: 'SET_DEVICE_MODEL_CHECK',
  SET_PHYSICAL_RESULT_CHECK: 'SET_PHYSICAL_RESULT_CHECK',
  SET_GOOD_WORKING_ORDER_CHECK: 'SET_GOOD_WORKING_ORDER_CHECK',
  SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
  UNSET_LOADING_INDICATOR: 'UNSET_LOADING_INDICATOR',
  SET_COUNTRY_NAME: 'SET_COUNTRY_NAME',
  SET_STATE_NAME: 'SET_STATE_NAME',
  SET_ORDER_TYPE: 'SET_ORDER_TYPE',
  GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
  GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
  SET_MDN_CHECKED: 'SET_MDN_CHECKED',
  ADD_MSG_OPEN_POPUP: 'ADD_MSG_OPEN_POPUP',
  ADD_MSG_CLOSE_POPUP: 'ADD_MSG_CLOSE_POPUP',
  ADD_POPUP_DATA: 'ADD_POPUP_DATA',
  SET_ADD_MESSAGE_FLAG: 'SET_ADD_MESSAGE_FLAG',
  SET_BAN_CHECKED: 'SET_BAN_CHECKED',
  SET_CUSTOMER_FORM_VALIDITY: 'SET_CUSTOMER_FORM_VALIDITY',
  SET_CAPTUREDETAILS_CHECKED: 'SET_CAPTUREDETAILS_CHECKED',
  SET_TWELVE_MONTHS_CREDIT: 'SET_TWELVE_MONTHS_CREDIT',
  SET_TWENTYFOUR_MONTHS_CREDIT: 'SET_TWENTYFOUR_MONTHS_CREDIT',
  SET_THIRTYSIX_MONTHS_CREDIT: 'SET_THIRTYSIX_MONTHS_CREDIT',
  SET_SERVICE_MNGNMNT_NO_CHECK: 'SET_SERVICE_MNGNMNT_NO_CHECK',
  GET_CITY_STATE_REQUEST: 'GET_CITY_STATE_REQUEST',
  GET_CITY_STATE_SUCCESS: 'GET_CITY_STATE_SUCCESS',
  GET_CITY_STATE_FAILURE: 'GET_CITY_STATE_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  SET_CURRENT_PASSWORD: 'SET_CURRENT_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_CONFIRM_NEW_PASSWORD: 'SET_CONFIRM_NEW_PASSWORD',
  SET_INVALID_PASSWORD: 'SET_INVALID_PASSWORD',
  SET_SERVICE_MNGMNT_NO: 'SET_SERVICE_MNGMNT_NO',
  SUBMIT_TRADE_INFO: 'SUBMIT_TRADE_INFO',
  SUBMIT_TRADE_INFO_SUCCESS: 'SUBMIT_TRADE_INFO_SUCCESS',
  SUBMIT_TRADE_INFO_FAILURE: 'SUBMIT_TRADE_INFO_FAILURE',
  GET_LOGISTICES_REQUEST: 'GET_LOGISTICES_REQUEST',
  GET_LOGISTICES_SUCCESS: 'GET_LOGISTICES_SUCCESS',
  SET_LOGISTICS_DATA: 'SET_LOGISTICS_DATA',
  SET_LOGISTICS_DATA_All: 'SET_LOGISTICS_DATA_ALL',
  SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_MAKE: 'SET_MAKE',
  SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_MAKE: 'SET_MAKE',
  SET_MODEL_VARRIANT: 'SET_MODEL_VARRIANT',
  LOGISTICS_LBL_CONDITION: 'LOGISTICS_LBL_CONDITION',
  LOGISTICS_LBL_STORE_CODE: 'LOGISTICS_LBL_STORE_CODE',
  LOGISTICS_LBL_STORE_NAME: 'LOGISTICS_LBL_STORE_NAME',
  VODAFONE_CREATE_ITEM_BUTTON: 'VODAFONE_CREATE_ITEM_BUTTON',
  GET_IMEI_VERIFICATION_REQUEST: 'GET_IMEI_VERIFICATION_REQUEST',
  GET_IMEI_VERIFICATION_SUCCESS: 'GET_IMEI_VERIFICATION_SUCCESS',
  GET_IMEI_VERIFICATION_FAILURE: 'GET_IMEI_VERIFICATION_FAILURE',
  SET_PRIMARY_IMEI_STATUS: 'SET_PRIMARY_IMEI_STATUS',
  SET_SECONDARY_IMEI_STATUS: 'SET_SECONDARY_IMEI_STATUS',
  NAVIGATE_TO_SEARCH: 'NAVIGATE_TO_SEARCH',
  SET_LOGISTICS_TAB: 'SET_LOGISTICS_TAB',
  SET_OPTIONS_PAGE: 'SET_OPTIONS_PAGE',
  SET_UPGRADE_TAB: 'SET_UPGRADE_TAB',
  SET_TRADEIN_TAB: 'SET_TRADEIN_TAB',
  SET_ISTRADE: 'SET_ISTRADE',
  SET_REPORTING_TAB: 'SET_REPORTING_TAB',
  DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  GET_FILE_REQUEST: 'GET_FILE_REQUEST',
  GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  SET_ORDER_DETAILS_TAB: 'SET_ORDER_DETAILS_TAB',
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',
  CREATE_BATCH_REQUEST: 'CREATE_BATCH_REQUEST',
  CREATE_BATCH_SUCCESS: 'CREATE_BATCH_SUCCESS',
  SET_PARTNER_STORE_CODE: 'SET_PARTNER_STORE_CODE',
  SET_PARTNER_STORE_NAME: 'SET_PARTNER_STORE_NAME',
  SET_SPAN_OPEN_OR_CLOSE: 'SET_SPAN_OPEN_OR_CLOSE',
  SET_IMEI_STATUS: 'SET_IMEI_STATUS',
  SET_BATCH_NUMBER: 'SET_BATCH_NUMBER',
  SET_BATCH_START_DATE: 'SET_BATCH_START_DATE',
  SET_BATCH_CONSIGNMENT_NUMBER: 'SET_BATCH_CONSIGNMENT_NUMBER',
  GET_LOGISTICES_BATCH_REQUEST: 'GET_LOGISTICES_BATCH_REQUEST',
  GET_LOGISTICES_BATCH_SUCCESS: 'GET_LOGISTICES_BATCH_SUCCESS',
  SET_LOGISTICS_BATCH_DATA: 'SET_LOGISTICS_BATCH_DATA',
  CREATE_SEARCH_OPEN_POPUP: 'CREATE_SEARCH_OPEN_POPUP',
  CREATE_SEARCH_CLOSE_POPUP: 'CREATE_SEARCH_CLOSE_POPUP',
  SET_SEARCH_ITEM_ID: 'SET_SEARCH_ITEM_ID',
  LOGISTICS_UPDATE_BUTTON: 'LOGISTICS_UPDATE_BUTTON',
  CREATE_PARCEL_REQUEST: 'CREATE_PARCEL_REQUEST',
  CREATE_PARCEL_SUCCESS: 'CREATE_PARCEL_SUCCESS',
  GENERATE_PDF: 'GENERATE_PDF',
  SET_LOGISTICS_STORE: 'SET_LOGISTICS_STORE',
  UPDATE_STORE_REQUEST: 'UPDATE_STORE_REQUEST',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  SET_CREDIT_TRADE_UP: 'SET_CREDIT_TRADE_UP',
  SET_CREDIT_TRADE_IN: 'SET_CREDIT_TRADE_IN',
  SET_BATCH_SELECTED_PAGE: 'SET_BATCH_SELECTED_PAGE',
  SET_RECURRING: 'SET_RECURRING',
  REDIRECT_TO_LOGISTICS: 'REDIRECT_TO_LOGISTICS',
  BACK_CLICK: 'BACK_CLICK',
  CREATE_CONTACT_POINT_REQUEST: 'CREATE_CONTACT_POINT_REQUEST',
  CREATE_CONTACT_POINT_SUCCESS: 'CREATE_CONTACT_POINT_SUCCESS',
  SET_SEND_LABEL_CHECK: 'SET_SEND_LABEL_CHECK',
  PUSH_NOTIFICATION_REQUEST: 'PUSH_NOTIFICATION_REQUEST',
  PUSH_NOTIFICATION_SUCCESS: 'PUSH_NOTIFICATION_SUCCESS',
  PUSH_NOTIFICATION_FAILURE: 'PUSH_NOTIFICATION_FAILURE',
  SEND_EMAIL_LABEL_REQUEST: 'SEND_EMAIL_LABEL_REQUEST',
  SEND_EMAIL_LABEL_SUCCESS: 'SEND_EMAIL_LABEL_SUCCESS',
  SEND_EMAIL_LABEL_FAILURE: 'SEND_EMAIL_LABEL_FAILURE',
  SET_TRADE_ID_NUMBER: 'SET_TRADE_ID_NUMBER',
  SHOW_CANVAS: 'SHOW_CANVAS',
  HIDE_CANVAS: 'HIDE_CANVAS',
  SHOW_CANVAS_REPRINT: 'SHOW_CANVAS_REPRINT',
  HIDE_CANVAS_REPRINT: 'HIDE_CANVAS_REPRINT',
  FORGORT_PASSWORD_REQUEST: 'FORGORT_PASSWORD_REQUEST',
  FORGORT_PASSWORD_SUCCESS: 'FORGORT_PASSWORD_SUCCESS',
  FORGORT_PASSWORD_FAILURE: 'FORGORT_PASSWORD_FAILURE',
  SET_VODAFONE_AGENT_ID: 'SET_VODAFONE_AGENT_ID',
  NAVIGATE_TO_FORGOT_PASSWORD: 'NAVIGATE_TO_FORGOT_PASSWORD',
  SET_VERIFICATION_CODE: 'SET_VERIFICATION_CODE',
  CONFIRM_PASSWORD_REQUEST: 'CONFIRM_PASSWORD_REQUEST',
  CONFIRM_PASSWORD_SUCCESS: 'CONFIRM_PASSWORD_SUCCESS',
  CONFIRM_PASSWORD_FAILURE: 'CONFIRM_PASSWORD_FAILURE',
  GET_ID_DOCES_REQUEST: 'GET_ID_DOCES_REQUEST',
  GET_ID_DOCES_SUCCESS: 'GET_ID_DOCES_SUCCESS',
  GET_ID_DOCES_FAILURE: 'GET_ID_DOCES_FAILURE',
  GET_ROLE_ACCESS_REQUEST: 'GET_ROLE_ACCESS_REQUEST',
  GET_ROLE_ACCESS_SUCCESS: 'GET_ROLE_ACCESS_SUCCESS',
  SET_UPFRONT: 'SET_UPFRONT',
  SET_CREATE_ITEMS_DATA: 'SET_CREATE_ITEMS_DATA',
  SET_BATCH_ITEMS_DATA: ' SET_BATCH_ITEMS_DATA',
  SET_NOTIFICATION_FLAG: 'SET_NOTIFICATION_FLAG',
  RESET_CUSTOMER_TRADE_ID_FIELD: 'RESET_CUSTOMER_TRADE_ID_FIELD',
  CREATE_BATCH_ONSUBMIT: 'CREATE_BATCH_ONSUBMIT',
  CREATE_BATCH_ONSUBMIT_SUCCESS: 'CREATE_BATCH_ONSUBMIT_SUCCESS',
  CREATE_PARCEL_ONSUBMIT_REQUEST: 'CREATE_PARCEL_ONSUBMIT_REQUEST',
  CREATE_PARCEL_ONSUBMIT_SUCCESS: 'CREATE_PARCEL_ONSUBMIT_SUCCESS',
  SET_WORKING_AS: 'SET_WORKING_AS',
  SET_STORE_AGENT_ROLE: 'SET_STORE_AGENT_ROLE',
  SET_REPORT_DETAILS_TAB: 'SET_REPORT_DETAILS_TAB',
  SET_REPORT_TRADE_ID_NUMBER: 'SET_REPORT_TRADE_ID_NUMBER',
  SET_REPORT_START_DATE: 'SET_REPORT_SRART_DATE',
  SET_REPORT_END_DATE: 'SET_REPORT_END_DATE',
  SET_REPORT_STATE: 'SET_REPORT_STATE',
  SET_REPORT_STATUS: 'SET_REPORT_STATUS',
  SET_REPORT_STORE_NAME: 'SET_REPORT_STORE_NAME',
  SET_OSOT_REPORT_DATA: 'SET_OSOT_REPORT_DATA',
  GET_REPORTING_DETAILS_REQUEST: 'GET_REPORTING_DETAILS_REQUEST',
  GET_REPORTING_DETAILS_SUCCESS: 'GET_REPORTING_DETAILS_SUCCESS',
  SET_REPORT_SELECTED_PAGE: 'SET_REPORT_SELECTED_PAGE',
  SET_SHOW_OSOT_REPORT_DATA: 'SET_SHOW_OSOT_REPORT_DATA',
  SET_UPFRONT: "SET_UPFRONT",
  SET_CREATE_ITEMS_DATA: "SET_CREATE_ITEMS_DATA",
  SET_BATCH_ITEMS_DATA: " SET_BATCH_ITEMS_DATA",
  SET_NOTIFICATION_FLAG: "SET_NOTIFICATION_FLAG",
  RESET_CUSTOMER_TRADE_ID_FIELD: "RESET_CUSTOMER_TRADE_ID_FIELD",
  CREATE_BATCH_ONSUBMIT: "CREATE_BATCH_ONSUBMIT",
  CREATE_BATCH_ONSUBMIT_SUCCESS: "CREATE_BATCH_ONSUBMIT_SUCCESS",
  CREATE_PARCEL_ONSUBMIT_REQUEST: "CREATE_PARCEL_ONSUBMIT_REQUEST",
  CREATE_PARCEL_ONSUBMIT_SUCCESS: "CREATE_PARCEL_ONSUBMIT_SUCCESS",
  SET_WORKING_AS:"SET_WORKING_AS",
  SET_STORE_AGENT_ROLE:"SET_STORE_AGENT_ROLE",
  CLOSE_ACTION_POPUP: "CLOSE_ACTION_POPUP",
  OPEN_ACTION_POPUP: "OPEN_ACTION_POPUP",
  SET_ESCALATION_CODE: "SET_ESCALATION_CODE",
  GET_DAX_IMAGE_REQUEST: "GET_DAX_IMAGE_REQUEST",
  GET_DAX_IMAGE_SUCCESS: "GET_DAX_IMAGE_SUCCESS",
  GET_DAX_IMAGE_FAILURE: "GET_DAX_IMAGE_FAILURE",
  SET_ORDER_TAB: 'SET_ORDER_TAB',
  SET_REPORT_CARE_CREDIT_TAB: 'SET_REPORT_CARE_CREDIT_TAB',
  SET_REPORT_SUMMARY_TAB: 'SET_REPORT_SUMMARY_TAB',
  SET_CUSTOMER_RESOLUTION_FORM_VALIDITY: 'SET_CUSTOMER_RESOLUTION_FORM_VALIDITY',
  SET_MASTER_VIEW_DATA:'SET_MASTER_VIEW_DATA',
  INITIALIZE_REQUEST:'INITIALIZE_REQUEST',

  SET_CONTACT_US_TAB: 'SET_CONTACT_US_TAB',
  GET_CONTACT_US_MASTER_DATA: 'GET_CONTACT_US_MASTER_DATA',
  GET_CONTACT_US_MASTER_DATA_SUCCESS: 'GET_CONTACT_US_MASTER_DATA_SUCCESS',
  SET_CONTACT_US_QUESTION: 'SET_CONTACT_US_QUESTION',
  SET_CONTACT_US_TRADEID: 'SET_CONTACT_US_TRADEID',
  SET_PAYROLL_ID: 'SET_PAYROLL_ID',
  SET_CONTACT_US_DEVICE_MAKE: 'SET_CONTACT_US_DEVICE_MAKE',
  SET_CONTACT_US_DEVICE_MODEL: 'SET_CONTACT_US_DEVICE_MODEL',
  SET_CONTACT_US_FIRST_NAME: 'SET_CONTACT_US_FIRST_NAME',
  SET_CONTACT_US_LAST_NAME: 'SET_CONTACT_US_LAST_NAME',
  SET_CONTACT_US_CONTACT_EMAIL: 'SET_CONTACT_US_CONTACT_EMAIL',
  SET_CONTACT_US_CONTACT_NUMBER: 'SET_CONTACT_US_CONTACT_NUMBER',
  SET_CONTACT_US_STATE: 'SET_CONTACT_US_STATE',
  SET_CONTACT_US_EXTRA_DETAILS: 'SET_CONTACT_US_EXTRA_DETAILS',
  SEND_ENQUIRY_EMAIL_REQUEST: 'SEND_ENQUIRY_EMAIL_REQUEST',
  SEND_ENQUIRY_EMAIL_SUCCESS: 'SEND_ENQUIRY_EMAIL_SUCCESS',
  RESET_CONTACT_US_FORM: 'RESET_CONTACT_US_FORM',
  SET_FAQ_DATA: 'SET_FAQ_DATA',
  GET_FAQ_LIST_REQUEST: 'GET_FAQ_LIST_REQUEST',
  GET_FAQ_LIST_SUCCESS: 'GET_FAQ_LIST_SUCCESS',
  SHOW_CONTACT_US_MESSAGE: 'SHOW_CONTACT_US_MESSAGE',
  SET_LOGISTICS_DEVICE_TYPE : 'SET_LOGISTICS_DEVICE_TYPE',
  CLEAR_DEVICE_LIST: 'CLEAR_DEVICE_LIST',
  GET_TRADE_DETAILS: 'GET_TRADE_DETAILS',
  GET_TRADE_DETAILS_SUCCESS: 'GET_TRADE_DETAILS_SUCCESS',
  SUBMIT_TRADE_DETAILS: 'SUBMIT_TRADE_DETAILS',
  SUBMIT_TRADE_DETAILS_SUCCESS: 'SUBMIT_TRADE_DETAILS_SUCCESS',
  SUBMIT_TRADE_DETAILS_V2: 'SUBMIT_TRADE_DETAILS_V2',
  SUBMIT_TRADE_DETAILS_SUCCESS_V2: 'SUBMIT_TRADE_DETAILS_SUCCESS_V2',
  GET_PRE_SIGNED_URL: 'GET_PRE_SIGNED_URL',
  GET_PRE_SIGNED_URL_SUCCESS: 'GET_PRE_SIGNED_URL_SUCCESS',
  UPLOAD_FILE_TO_S3: 'UPLOAD_FILE_TO_S3',
  UPLOAD_FILE_TO_S3_SUCCESS: 'UPLOAD_FILE_TO_S3_SUCCESS',
  CLEAR_TRADE_DETAILS: 'CLEAR_TRADE_DETAILS',
  VERIFY_IMEI: 'VERIFY_IMEI',
  VERIFY_IMEI_SUCCESS: 'VERIFY_IMEI_SUCCESS',
  SET_TRADE_SUBMITTED: 'SET_TRADE_SUBMITTED',
  GET_TRADE_DETAILS_FAILURE: 'GET_TRADE_DETAILS_FAILURE'
}
